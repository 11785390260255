import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';

import { Spinner } from 'react-bootstrap';

import axios from 'axios';

import store from '../../redux/store';

import { useSelector, useDispatch  } from 'react-redux'
import { setIsLoadingEqs40 } from '../../redux/actions/commonAction';
const GetIsLoading = () => {
    return useSelector((state) => state.common.isLoadingEqs40);
}

const DeliverySimulationTableComponent = (props) => {
    const dispatch = useDispatch();
    const filters = useSelector((state) => state.filters);

    const [eqContainers40, setEqContainers40] = useState(0);
    const [isLoading, setIsLoading] = useState(GetIsLoading());

    const fetchEqContainers = async () => {
        var res = await axios.post(`eq_containers40`, filters);
        setEqContainers40(res.data);
        dispatch(setIsLoadingEqs40(false));

        setIsLoading(false);
    };

    store.subscribe(() => {
        var s = store.getState();
        if ((s.common.isLoadingEqs40  || s.common.isLoading) && !isLoading) {
            setIsLoading(true);
        }
    });

    useEffect(() => {
        if (isLoading) {
            fetchEqContainers();
        }
    }, [isLoading]);

    return (
        <Table bordered hover size="sm">
            <thead>
                <tr>
                    <th colSpan="2">Simulateur Transport</th>
                </tr>
            </thead>
            
            <tbody>
            { isLoading ? 
                <tr><td><Spinner animation="border" /></td></tr>:
                <>
                    <tr>
                        <td>Eq. Containers 20"</td>
                        <td>{ (eqContainers40 * 2).toFixed(3)}</td>
                    </tr>
                    <tr>
                        <td>Eq. Containers 40" ou Camions</td>
                        <td>{ eqContainers40.toFixed(3) }</td>
                    </tr>
                </>
            }
            </tbody>

        </Table>
    );
}

export default DeliverySimulationTableComponent;