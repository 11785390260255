import { useState } from 'react';
import { useDispatch } from 'react-redux'
import { setToken } from '../redux/actions/commonAction';

export default function useToken() {

    const dispatch = useDispatch();

    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        return userToken
    };

    const [tokenL, setTokenL] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem('token', JSON.stringify(userToken));
        dispatch(setToken(userToken));
        setToken(userToken);
        setTokenL(userToken)
    };

    const deleteToken = () => {
        localStorage.removeItem('token');
        dispatch(setToken(undefined));
        setToken(undefined);
        setTokenL(undefined)
        window.location.reload(false);
    };

    return {
        setToken: saveToken,
        token : tokenL,
        deleteToken
    }
}