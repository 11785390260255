import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { Col } from 'react-bootstrap';

import Select from 'react-select'
import axios from 'axios';
import { setFilters } from '../../redux/actions/filterAction';
import { ActionTypes } from '../../redux/constants/filterActionTypes';
import { setIsLoading } from '../../redux/actions/commonAction';

const GetIsLoading = () => {
    return useSelector((state) => state.common.isLoadingEqs40);
}

const TableFiltersComponent = () => {

    const dispatch = useDispatch();
    const filters = useSelector((state) => state.filters);

    const [isLoadingFilters, setIsLoadingFilters] = useState(false);

    const [references, setReferences] = useState([]);
    const [referencesOptions,  setReferencesOptions] = useState([]);

    const [providers, setProviders] = useState([]);
    const [providersOptions, setProvidersOptions] = useState([]);

    const [providersCodes, setProvidersCodes] = useState([]);
    const [providersCodesOptions, setProvidersCodesOptions] = useState([]);

    const [productFamilies, setProductFamilies] = useState([]);
    const [productFamiliesOptions, setProductFamiliesOptions] = useState([]);

    const [productSubFamilies, setProductSubFamilies] = useState([]);
    const [productSubFamiliesOptions, setProductSubFamiliesOptions] = useState([]);

    const [as, setAs] = useState([]);
    const [asOptions, setAsOptions] = useState([]);

    const mapDataToOption = (data, setter) => {
        var options = [];

        data.map(p =>
            options.push({
                value: p,
                label:  p
            })
        );
        setter(options);
    };

    const fetchData = async () => {
        await axios.post(`filters/`, filters)
            .then((d) => {
                setReferences(d.data['references']);
                setProviders(d.data['providers']);
                setProvidersCodes(d.data['providers_codes']);
                setProductFamilies(d.data['families']);
                setProductSubFamilies(d.data['sub_families']);
                setAs(d.data['as']);
            })
            .catch((err) => {
                console.log(err)
            });
    };

    useEffect(() => {
        mapDataToOption(references, setReferencesOptions);
    }, [references]);

    useEffect(() => {
        mapDataToOption(providers, setProvidersOptions);
    }, [providers]);

    useEffect(() => {
        mapDataToOption(providersCodes, setProvidersCodesOptions);
    }, [providersCodes]);

    useEffect(() => {
        mapDataToOption(productFamilies, setProductFamiliesOptions);
    }, [productFamilies]);

    useEffect(() => {
        mapDataToOption(productSubFamilies, setProductSubFamiliesOptions);
    }, [productSubFamilies]);

    useEffect(() => {
        mapDataToOption(as, setAsOptions);
    }, [as]);

    useEffect(() => {
        if(isLoadingFilters)
        {
            fetchData();
            setIsLoadingFilters(false)
            dispatch(setIsLoading(true));
        }
    }, [filters]);

    useEffect(() => {
        setIsLoadingFilters(true)
    }, []);


    const handleFilterChange = (value, actionType) => {
        setIsLoadingFilters(true)
        dispatch(setFilters(value, actionType));
    };

    return (
        <>
            <Col sm>
                <Select options={referencesOptions} placeholder="Code article" isClearable={true} isSearchable={true} onChange={(value) => handleFilterChange(value, ActionTypes.SET_REFERENCES_FILTERS)} isMulti={true}/>
            </Col>
            <Col sm>
                <Select options={providersCodesOptions} placeholder="Code fournisseur" isClearable={true} isSearchable={true} onChange={(value) => handleFilterChange(value, ActionTypes.SET_PROVIDER_CODES_FILTERS)} isMulti={true}/>
            </Col>
            <Col sm>
                <Select options={providersOptions} placeholder="Fournisseur" isClearable={true} isSearchable={true} onChange={(value) => handleFilterChange(value, ActionTypes.SET_PROVIDER_FILTERS)} isMulti={true}/>
            </Col>
            <Col sm>
                <Select options={productFamiliesOptions} placeholder="Famille produit" isClearable={true} isSearchable={true} onChange={(value) => handleFilterChange(value, ActionTypes.SET_FAMILY_FILTERS)} isMulti={true} />
            </Col>
            <Col sm>
                <Select options={productSubFamiliesOptions} placeholder="Sous Famille" isClearable={true} isSearchable={true} onChange={(value) => handleFilterChange(value, ActionTypes.SET_SUB_FAMILY_FILTERS)} isMulti={true}/>
            </Col>
            <Col sm>
                <Select options={asOptions} placeholder="Arrêt gamme (A)" isClearable={true} isSearchable={true} onChange={(value) => handleFilterChange(value, ActionTypes.SET_A_FILTERS)}  isMulti={true} />
            </Col>
        </>
    );
}

export default TableFiltersComponent;