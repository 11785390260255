import React, { useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import { useTable, useBlockLayout } from "react-table";
import { useSticky } from "react-table-sticky";
import { Accordion, Stack, Col, Spinner, Pagination, Form } from 'react-bootstrap';

import axios from 'axios';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector, useDispatch } from 'react-redux';
import store from '../../redux/store';

import toastr from 'toastr';
import 'toastr/build/toastr.css'
import { setIsLoading, setIsLoadingEqs40 } from '../../redux/actions/commonAction';

const BASE_API_URL = process.env.REACT_APP_API_URL;

const Styles = styled.div`
  padding: 1rem;
  
  .ReactTable .rt-thead {
    overflow-y: scroll;
  }

  .table {
    border: 1px solid #ddd;

    .tr {
      :last-child {
        .td {
          border-bottom: 0;
        }
      }
    }

    .th,
    .td {
      padding: 5px;
      border-bottom: 1px solid #ddd;
      border-right: 1px solid #ddd;
      background-color: #fff;
      overflow: hidden;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        width: 5px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;

        &.isResizing {
          background: red;
        }
      }
    }

    &.sticky {
      overflow: scroll;
      .header,
      .footer {
        position: sticky;
        z-index: 1;
        width: fit-content;
      }

      .header {
        top: 0;
        box-shadow: 0px 3px 3px #ccc;
      }

      .footer {
        bottom: 0;
        box-shadow: 0px -3px 3px #ccc;
      }

      .body {
        position: relative;
        z-index: 0;
      }

      [data-sticky-td] {
        position: sticky;
      }

      [data-sticky-last-left-td] {
        box-shadow: 2px 0px 3px #ccc;
      }

      [data-sticky-first-right-td] {
        box-shadow: -2px 0px 3px #ccc;
      }
    }
  }
`;

function Table({ columns, data, hiddenColumns, changeHidden, isLoading, page, pagesNumber, setPage, pageSize, setPageSize }) {
    const defaultColumn = useMemo(
        () => ({
            minWidth: 150,
            width: 150,
            maxWidth: 400
        }),
        []
    );

    const {
        setHiddenColumns,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        allColumns,
        prepareRow
    } = useTable(
        {
            columns,
            data,
            defaultColumn,
            initialState: {
                hiddenColumns: hiddenColumns
            }
        },
        useBlockLayout,
        useSticky
    );

    let items = [];
    for (let number = 1; number <= pagesNumber; number++) {
      items.push(
        <Pagination.Item key={number} active={number === page} onClick={() => setPage(number)} disabled={isLoading}>
          {number}
        </Pagination.Item>,
      );
    }
    
    const pageSizes = [10, 20, 50, 100, 500, 1000];

    const selectOptions = pageSizes.map(x => <option key={x} value={x}>{x}</option>);

    var paginationAndSelect = 
    <div>
        <Col sm={"6"} className="mb-3">
            <Pagination>{items}</Pagination>
        </Col>
        <Col sm={"2"}>
            <Form.Select onChange={(event) => setPageSize(parseInt(event.target.value))} value={pageSize}>
                {selectOptions}
            </Form.Select>
        </Col>
    </div>;

    return (
        <Styles>
            <Col sm={"auto"} className="mb-3">
                <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>Changer la visibilité des colonnes</Accordion.Header>
                        <Accordion.Body>
                            <Stack direction="horizontal" gap={3}>
                                {allColumns.filter(x => x.hasChildren === false).map(column =>
                                (
                                    <label key={column.id}>
                                        <input type="checkbox" {...column.getToggleHiddenProps()} onClick={(e) => changeHidden(column, setHiddenColumns, e)} checked={column.isVisible} />{' '}
                                        {column.Header}
                                    </label>
                                ))
                                }
                            </Stack>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Col>

            {paginationAndSelect}
            <br />
            <div
                {...getTableProps()}
                className="table sticky"
                style={{ width: '100%', height: 'auto' }}
            >

                <div className="header">
                    {headerGroups.map(headerGroup => (
                        <div {...headerGroup.getHeaderGroupProps()} className="tr">
                            {headerGroup.headers.map(column => {

                                var content = column.hasChildren ? <FontAwesomeIcon icon={faArrowRight} /> : <></>;
                                return (
                                    <div
                                        {...column.getHeaderProps()} className="th" onClick={(e) => changeHidden(column, setHiddenColumns, e)}>
                                        {!column.isChildren ? <b>{column.render("Header")}</b> : <>{column.render("Header")}</>}{' '}{content}
                                    </div>
                                )
                            })}
                        </div>
                    ))}
                </div>
                <div style={{ maxHeight: '700px' }}>
                    { isLoading ?
                        <div>
                            <Spinner animation="border" />
                        </div> :
                        <div {...getTableBodyProps()} className="body">
                            { rows.length > 0 ?
                                <>
                                    {rows.map((row, i) => {
                                        prepareRow(row);
                                        return (
                                            <div {...row.getRowProps()} className="tr">
                                                {row.cells.map(cell => {
                                                    var val = parseInt(cell.value);
                                                    return (
                                                        <div {...cell.getCellProps()} className={"td " + (val !== "NaN" && val === 0 ? "text-secondary" : "")}>
                                                            {cell.render("Cell")}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </>
                                :
                                <>
                                    Aucune donnée n'a été trouvée
                                </>
                            }
                        </div>
                    }
                </div>
            </div>
           {paginationAndSelect}
        </Styles>
    );
}

const GetIsLoading = () => {
    return useSelector((state) => state.common.isLoading);
}

const GetFilters = () => {
    return useSelector((state) => { return state.filters; })
 };

const TableComponent = (props) => {
    const dispatch = useDispatch();

    const filters = useSelector((state) => state.filters);
    
    const [headers, setHeaders] = useState([]);
    const [data, setData] = useState([]);
    const [hiddenColumns, setHiddenColumns] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(false);

    const [activeFiltersCount, setActiveFiltersCount] = useState(0);

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(100);
    const [pagesNumber, setPagesNumber] = useState(0);

    const changeHidden = (column, setHiddenColumns2, e) => {

        if (e.currentTarget.className === "th" && !column.hasChildren) {
            return;
        }
        var id = column.id;
        var concernedColumns = column.hasChildren ? headers.filter(x => x.accessor.startsWith(id + '-')) : headers.filter(x => x.accessor === id);

        var result = [];

        if (hiddenColumns.filter(x => x.startsWith(id + '-') || x === id).length > 0) {
            var accessors = concernedColumns.map(x => x.accessor);
            result = hiddenColumns.filter(function (el) {
                return accessors.indexOf(el) < 0;
            });
        }
        else {

            result = [...hiddenColumns, ...concernedColumns.map(x => x.accessor)];
        }

        setHiddenColumns(result);
        setHiddenColumns2(result);
    }

    const handleValidateAmountChange = (e, props) => {
        e.stopPropagation();

        var newValue = e.target.value;
        var article = props.cell.row.original;

        let items = [...props.data];
        var dd = props.data.find(x => x.id === article.id);
        var idx = props.data.indexOf(dd);

        items[idx].validated_amount = newValue;
        setData(items);

        updateReplenishment(article.id, newValue); 
    }

    const updateReplenishment = (id, value) => {
        axios.post(`article/replenishment`, {
            id : id,
            value : value
        }).then(function(){
            toastr.options.timeOut = 3000;
            toastr.success('La quantité validée a été enregistrée');
            dispatch(setIsLoadingEqs40(true));
        });
    }

    useEffect(() => {
        const fetchThs = async () => {
            var res = await axios.get(`analyseTableColumns`);
            setPagesNumber(res.data.pageNumber);
            setPageSize(res.data.pageSize);

            var ths = [];
            res.data.columns.forEach((columnData, i) => {
                var hasChildren = columnData.hasOwnProperty('children');

                var th = {
                    Header: columnData.title,
                    accessor: columnData.id,
                    hasChildren: hasChildren
                };

                if (columnData.id === 'validated_amount') {
                    th.Cell = props => <input type="number" min="0" className={"form-control text-center " + (props.value > 0 ? "bg-success": "")} value={props.value} onChange={(e) => handleValidateAmountChange(e, props)} /> // Custom cell components!
                }
                else if (columnData.id === 'eq_containters') {
                    th.Cell = props => {
                        var d = props.row.original;
                        var validated_amount = d.validated_amount;

                        if(validated_amount === 0)
                            return 0;
                        return parseFloat((1 / d.qtPourRemplirUnContainer40OuCamion * validated_amount) * 2 + (1 / d.qtPourRemplirUnContainer40OuCamion * validated_amount)).toFixed(2);
                    }
                }


                if (!hasChildren) {
                    th.sticky = 'left';
                }

                ths.push(th);

                if (hasChildren) {

                    columnData.children.forEach(child => {
                        hiddenColumns.push(`${columnData.id}-${child}`)
                        var cTh = {
                            Header: child,
                            accessor: `${columnData.id}-${child}`,
                            isChildren: true
                        };
                        
                        if (columnData.id === 'eq_containters')
                        {
                            if(child === "20")
                            {
                                cTh.Cell = props => {
                                    var d = props.row.original;
                                    var validated_amount = d.validated_amount;
    
                                    if(validated_amount === 0)
                                        return 0;
                                    return parseFloat((1 / d.qtPourRemplirUnContainer40OuCamion * validated_amount) * 2).toFixed(2);
                                }
                            }
                            else
                            {
                                cTh.Cell = props => {
                                    var d = props.row.original;
                                    var validated_amount = d.validated_amount;
    
                                    if(validated_amount === 0)
                                        return 0;
                                    return parseFloat((1 / d.qtPourRemplirUnContainer40OuCamion * validated_amount)).toFixed(2);
                                }
                            }
                        }
                        // else if (columnData.id === 'cosmos')
                        // {
                        //     cTh.Cell = props => {
                        //         if(props.value != 0)
                        //         {
                        //             return <b className="text-danger font-weight-bold">cosmos</b>
                        //         }
                        //         return <div style={{boxSizing: "border-box", width:"100%", padding:"0"}} className="bg-success">&nbsp;</div>
                        //     }
                        // }
                        // else if (columnData.id === 'ruptures')
                        // {
                        //     cTh.Cell = props => {
                        //         if(props.value == 0)
                        //         {
                        //             return <b className="text-danger font-weight-bold">ruptures</b>
                        //         }
                        //         return <div style={{width:"100%", height: "100%"}} className="bg-success">&nbsp;</div>
                        //     }
                        // }

                        ths.push(cTh)
                    });
                }
            });
            setHeaders(ths);
        };
        fetchThs();
    }, []);

    const fetchArticles = async () => {
        if(Object.entries(filters).filter(x => x[1].length > 0).length !== activeFiltersCount)
        {
            setPage(1);
        }
        var payload = {
            filters,
            'page' : page,
            'pageSize' : pageSize
        }
        var res = await axios.post(`articles`, payload);
        var resData = res.data;

        setData(resData.data);
        setPagesNumber(res.data.pageNumber);
        setIsTableLoading(false);
        dispatch(setIsLoading(false));
        setActiveFiltersCount(Object.entries(filters).filter(x => x[1].length > 0).length);
    };

    // const handleScroll = () => {
    //     // const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight
    //     // if (bottom) {
    //     //   setPage(page + 1);
    //     // }
    //   };

    store.subscribe(() => {
        var s = store.getState();
        if (s.common.isLoading) {
            setIsTableLoading(true);
        }
    });

    // useEffect(() => {
    //     window.addEventListener('scroll', handleScroll, {
    //       passive: true
    //     });
    
    //     return () => {
    //       window.removeEventListener('scroll', handleScroll);
    //     };
    // }, []);

    useEffect(() => {
        if (isTableLoading) {
            fetchArticles();
        }
    }, [isTableLoading]);

    useEffect(() => {
        if(!isTableLoading)
        {
            setIsTableLoading(true)
        }
      }, [page, pageSize]);

    return <Table 
    page={page}
    pagesNumber={pagesNumber}
    setPage={setPage}
    columns={headers} 
    data={data} 
    hiddenColumns={hiddenColumns} 
    changeHidden={changeHidden} 
    isLoading={isTableLoading}
    pageSize={pageSize}
    setPageSize={setPageSize}
    
    />;
}

export default TableComponent;
