import { ActionTypes } from "../constants/filterActionTypes";

const initialState =  {
    references : [],
    providers : [],
    providers_codes : [],
    families : [],
    sub_families : [],
    as : []
};

export const filterReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_FAMILY_FILTERS:
            state['families'] = action.payload;
            break;
        case ActionTypes.SET_SUB_FAMILY_FILTERS:
            state['sub_families'] = action.payload;
            break;
        case ActionTypes.SET_PROVIDER_FILTERS:
            state['providers'] = action.payload;
            break;
        case ActionTypes.SET_PROVIDER_CODES_FILTERS:
            state['providers_codes'] = action.payload;
            break;
        case ActionTypes.SET_REFERENCES_FILTERS:
            state['references'] = action.payload;
            break;
        case ActionTypes.SET_A_FILTERS:
            state['as'] = action.payload;
            break;
        default:
            break;
    }
    return Object.assign({}, state);

}