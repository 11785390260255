import { ActionTypes } from "../constants/commonActionTypes.js";

export const setIsAdmin = (isAdmin) => {
    return {
        type : ActionTypes.SET_IS_ADMIN,
        payload : isAdmin
    };
}

export const setToken = (token) => {
    return {
        type : ActionTypes.SET_TOKEN,
        payload : token
    };
}

export const setIsLoading = (isLoading) => {
    return {
        type : ActionTypes.SET_IS_LOADING,
        payload : isLoading
    };
}

export const setIsLoadingEqs40 = (isLoadingEqs40) => {
    return {
        type : ActionTypes.SET_IS_LOADING_EQS40,
        payload : isLoadingEqs40
    };
}

export const setLastTrtDate = (date) => {
    return {
        type : ActionTypes.SET_LAST_TRT_DATE,
        payload : date
    };
}