import React, { useState, useEffect } from 'react';
import { Modal, Button, ListGroup, CloseButton, Table } from 'react-bootstrap';
import { faRedo, faSearch, faTrashAlt, faCog, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import ImportDataToDWHComponent from '../ImportDataToDWHComponent';
import axios from 'axios';

import toastr from 'toastr';
import 'toastr/build/toastr.css'


const DeleteReplenishmentDWHModal = (props) => {
    const [deleteReplenishmentContent, setDeleteReplenishmentContent] = useState('');

    const deleteLastReplenishments = async () => {
        await axios.post(`deletelastReplenishments`)
            .then(() => {
                toastr.success('Les données ont été supprimés');
                setDeleteReplenishmentContent('');
                props.setShowDeleteReplenishmentDWHModal(false)
            })
    }
    
    
    useEffect(() => {
        if(props.showDeleteReplenishmentDWHModal)
        {
            openDeleteReplenishmentDWHModal();
        }
    }, [props.showDeleteReplenishmentDWHModal])

    const openDeleteReplenishmentDWHModal = async () => {
        await axios.get(`/lastReplenishments`)
            .then(function (d) {
                var data = d.data;
                if (data.date !== undefined) {
                    var content = <>
                        <p>
                            Voulez-vous supprimer les quantiés validées datant du <b className="text-primary">{data.date}</b> ?
                        </p>
                        <p>
                            <ListGroup>
                                {data.data.map(x => <ListGroup.Item key={x.id}>Quantité : <b>{x.amount}</b> pour l'article <b>{x.article}</b></ListGroup.Item>)}
                            </ListGroup>
                        </p>
                    </>;
                    setDeleteReplenishmentContent(content);
                }
                else {
                    toastr.options.timeOut = 3000;
                    toastr.info("Aucun réapprovisionnement n'a été trouvé");
                }
            })
    }

    return (
        <Modal
            show={props.showDeleteReplenishmentDWHModal && deleteReplenishmentContent != ""}
            size="lg"
        >
            <Modal.Header>
                <Modal.Title>Suppression des quantiés validées</Modal.Title>
                <CloseButton onClick={() => props.setShowDeleteReplenishmentDWHModal(false)} />
            </Modal.Header>
            <Modal.Body>
                {deleteReplenishmentContent}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => deleteLastReplenishments()}>Oui</Button>
                <Button variant="secondary" onClick={() => props.setShowDeleteReplenishmentDWHModal(false)} >
                    Non
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default DeleteReplenishmentDWHModal;