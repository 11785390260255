import React, { useState, useEffect } from 'react';
import { Modal, CloseButton, Table } from 'react-bootstrap';
import axios from 'axios';

import toastr from 'toastr';
import 'toastr/build/toastr.css'
import AddUserManagerModal from './AddUserManagerModal';
import EditUserManagerModal from './EditUserManagerModal';
import DeleteUserManagerModal from './DeleteUserManagerModal';


const UserManagerModal = (props) => {
    const [userManagerContent, setUserManagerContent] = useState('');

    const [showAddUserManagerModal, setShowAddUserManagerModal] = useState(false);

    const [showEditUserManagerModal, setShowEditUserManagerModal] = useState(false);
    const [userToEdit, setUserToEdit] = useState({});

    const [showDeleteUserManagerModal, setShowDeleteUserManagerModal] = useState(false);
    const [userToDelete, setUserToDelete] = useState({});

    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        if (props.showUserMangerModal || (!hidden && props.showUserMangerModal)) {
            openUserManagerModal();
        }
    }, [props.showUserMangerModal, hidden])

    const openUserManagerModal = async () => {
        await axios.get(`users`)
            .then(function (d) {
                var data = d.data;
                var res = data.map(x =>
                    <tr>
                        <td>{x.id}</td>
                        <td>{x.email}</td>
                        <td>{x.admin ? 'Oui' : 'Non'}</td>
                        <td><button className="btn btn-warning" onClick={() =>{setHidden(true); setShowEditUserManagerModal(true); setUserToEdit(x)}}>Modifier</button></td>
                        <td><button className="btn btn-danger" onClick={() => {setHidden(true); setShowDeleteUserManagerModal(true); setUserToDelete(x) }}>Suppimer</button></td>
                    </tr>)
                setUserManagerContent(res);
            })
    }


    return (
        <Modal
            show={props.showUserMangerModal}
            id="userManagerModal"
            style={hidden ? { zIndex: "545" } : {}}
            size="xl"
        >
            <Modal.Header>
                <Modal.Title>Gestion des utilisateurs</Modal.Title>
                <CloseButton onClick={() => props.setShowUserMangerModal(false)} />
            </Modal.Header>
            <Modal.Body>
                <AddUserManagerModal
                    showAddUserManagerModal={showAddUserManagerModal}
                    setShowAddUserManagerModal={setShowAddUserManagerModal}
                    setShowUserManagerModal={props.setShowUserManagerModal}
                    setHidden={setHidden}
                />
                <EditUserManagerModal
                    showEditUserManagerModal={showEditUserManagerModal}
                    setShowEditUserManagerModal={setShowEditUserManagerModal}
                    setShowUserManagerModal={props.setShowUserManagerModal}
                    setHidden={setHidden}
                    setUserToEdit={setUserToEdit}
                    userToEdit={userToEdit}
                />
                 <DeleteUserManagerModal
                    showDeleteUserManagerModal={showDeleteUserManagerModal}
                    setShowDeleteUserManagerModal={setShowDeleteUserManagerModal}
                    setShowUserMangerModal={props.setShowUserMangerModal}
                    setHidden={setHidden}
                    setUserToDelete={setUserToDelete}
                    userToDelete={userToDelete}
                />

                <button className="btn btn-success" onClick={() => {
                    setHidden(true)
                    setShowAddUserManagerModal(true)
                }} >Ajouter</button>
                <Table>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Email</th>
                            <th>Administrateur</th>
                            <th>Modifier</th>
                            <th>Supprimer</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userManagerContent}
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>)
}
export default UserManagerModal;