import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal, Button, ListGroup, CloseButton, Form, Label } from 'react-bootstrap';
import axios from 'axios';

import toastr from 'toastr';
import 'toastr/build/toastr.css'
import TitleComponent from './Others/TitleComponent';

const LoginComponent = (props) => {
    const setToken = props.setToken;

    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');


    const onSubmitLogin = async(e) => {
        e.preventDefault();
        
        await axios.post('login_check', {
            username : login,
            password : password
        })
        .then(async (d) => {
            var token = d.data.token;
   
            setToken(token)
            toastr.success('Connexion réussie');
        }).catch((a) => {
            toastr.error('Mauvais identifiant / mot de passe.');
        })
    }

    return (
        <Container>
            <Row className="mt-8">
                <Col >
                    <TitleComponent text="Copabuy" color="secondary"  />
                    <Form onSubmit={ (e) => { onSubmitLogin(e) }}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control type="email" placeholder="Email" value={login} onChange={(e) => {setLogin(e.target.value)}}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>Mot de passe</Form.Label>
                            <Form.Control type="password" placeholder="Mot de passe" value={password} onChange={(e) => {setPassword(e.target.value)}}/>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Connexion
                        </Button>
                    </Form>
                </Col>

            </Row>
        </Container>
    );
}

export default LoginComponent;