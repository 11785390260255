import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'react-router-dom';

const IconButtonComponent = (props) => {
    var btnContent =
        <Button
            variant="default"
            className="bg-pvgreen"
            size="lg"
            style={{ width: "335px", height: "75px" }}
            onClick={props.onClick}
        >
            <FontAwesomeIcon icon={props.icon} />{' '}
            {props.text}
        </Button>
        ;
    return (
        props.to !== undefined ? <Link className='text-link' to={props.to}>{btnContent}</Link> : <>{btnContent}</>
    );
}

export default IconButtonComponent;