import React, { useState, useEffect } from 'react';
import { Modal, Button, CloseButton, Form } from 'react-bootstrap';
import axios from 'axios';

import toastr from 'toastr';
import 'toastr/build/toastr.css'

const DeleteUserManagerModal = (props) => {
    const deleteUser = async (e) => {
        await axios.delete(`users/${props.userToDelete.id}`)
                    .then(() => {
                        toastr.success('Utilisateur supprimé');
                        props.setHidden(false)
                        props.setShowDeleteUserManagerModal(false); 
                    }).catch(() => {
                       // toastr.error('Un utilisateur possédant cette adresse email existe déjà');
                    })
    }


    return (
        <Modal
            show={props.showDeleteUserManagerModal}
            size="lg"
        >
            <Modal.Header>
                <Modal.Title>Supprimer un utilisateur</Modal.Title>
                <CloseButton onClick={() => { props.setShowDeleteUserManagerModal(false); props.setHidden(false) }} />
            </Modal.Header>
            <Modal.Body>
                Supprimer l'utilisateur <b>{props.userToDelete.email}</b> ?
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={() => deleteUser()}>Oui</Button>
                <Button variant="secondary" onClick={() => {props.setShowDeleteUserManagerModal(false); props.setHidden(false);}} >
                    Non
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
export default DeleteUserManagerModal;