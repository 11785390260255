import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './assets/css/App.scss';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'

import HomeComponent from './components/HomeComponent';
import SettingsComponent from './components/SettingsComponent';
import AnalyseComponent from './components/AnalyseComponent';


import toastr from 'toastr';
import 'toastr/build/toastr.css'
import LoginComponent from './components/LoginComponent';
import useToken from './hooks/useToken';

import axios from 'axios';

function App() {
  const { token, setToken } = useToken();

  toastr.options.toastClass = 'toastr';

  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers.common = {
    'Authorization': 'Bearer ' + token
  };

  return (
    <div className="App">
      {!token ?
          <LoginComponent setToken={setToken} />
        :
        <Router>
          <Switch>
            <Route path="/" exact component={HomeComponent}></Route>
            <Route path="/settings" exact component={SettingsComponent}></Route>
            <Route
              path="/analyse"
              exact
              render={() =>
                <div>
                  <AnalyseComponent />
                </div>
              }>
            </Route>
          </Switch>
        </Router>
      }
    </div>
  );
}

export default App;
