import React, { useState } from 'react';
import { Modal, Button, CloseButton, Form } from 'react-bootstrap';
import axios from 'axios';

import toastr from 'toastr';
import 'toastr/build/toastr.css'

const AddUserManagerModal = (props) => {
    
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);

    const addUser = async (e) => {
        e.preventDefault();
        await axios.post(`users/`, 
                    {
                        username : login,
                        password : password,
                        isAdmin : isAdmin
                    })
                    .then(() => {
                        toastr.success('Utilisateur ajouté');
                        props.setShowAddUserManagerModal(false);
                        props.setHidden(false);
                    }).catch(() => {
                        toastr.error('Un utilisateur possédant cette adresse email existe déjà');
                    })
    }

    return (
        <Modal
            show={props.showAddUserManagerModal}
            size="lg"
        >
            <Modal.Header>
                <Modal.Title>Ajout d'un utilisateur</Modal.Title>
                <CloseButton onClick={() => { props.setShowAddUserManagerModal(false); props.setHidden(false) }} />
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(e) => addUser(e)}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={login} onChange={(e) => {setLogin(e.target.value)}} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <Form.Label>Mot de passe</Form.Label>
                        <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => {setPassword(e.target.value)}} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Est administateur" value={isAdmin} onChange={(e) => {setIsAdmin(!isAdmin)}}/>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Valider
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
export default AddUserManagerModal;