import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector  } from 'react-redux'

import axios from 'axios';
import { setLastTrtDate } from '../../redux/actions/commonAction';

const GetLastTrtDate = () => {
    return useSelector((state) => state.common.date);
}

const LastImportLabelComponent = (props) => {
    const dispatch = useDispatch();

    const [date, setDate] = useState(GetLastTrtDate());

    const fetchData = async () => {
        await axios.get(`last_trt_date`)
            .then((d) => {
                setDate(d.data);
                dispatch(setLastTrtDate(d.data));
            })
            .catch((err) => {
                console.log(err)
            });
    };

    useEffect(() => {
        if(date === '')
        {
            fetchData();
        }
    }, [date]);

    return (
        <label style={{ fontFamily: "Bangers" }} className="text-secondary">Date de dernier import des données en datawarehouse: {date}</label>
    );
}

export default LastImportLabelComponent;