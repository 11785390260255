import React, { useState, useEffect } from 'react';
import { Modal, Button, CloseButton, Form } from 'react-bootstrap';
import axios from 'axios';

import toastr from 'toastr';
import 'toastr/build/toastr.css'


const EditUserManagerModal = (props) => {
    const [login, setLogin] = useState('');
    const [password, setPassword] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);

    const editUser = async (e) => {
        e.preventDefault();
        await axios.put(`users/${props.userToEdit.id}`, 
                    {
                        username : login,
                        password : password,
                        isAdmin : isAdmin
                    })
                    .then(() => {
                        toastr.success('Utilisateur modifié');
                        props.setHidden(false);
                        props.setShowEditUserManagerModal(false);
                        props.setShowUserManagerModal(false);
                    }).catch(() => {
                       // toastr.error('Un utilisateur possédant cette adresse email existe déjà');
                    })
    }

    useEffect(() => {
        setLogin(props.userToEdit?.email);
        setPassword('');
        setIsAdmin(props.userToEdit?.admin);
    }, [props.userToEdit?.email])

    return (
        <Modal
            show={props.showEditUserManagerModal}
            size="lg"
        >
            <Modal.Header>
                <Modal.Title>Modifier un utilisateur</Modal.Title>
                <CloseButton onClick={() => { props.setShowEditUserManagerModal(false); props.setHidden(false) }} />
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={(e) => editUser(e)}>
                    <Form.Group className="mb-3" controlId="editUser">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={login} onChange={(e) => {setLogin(e.target.value)}} />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="editPassord">
                        <Form.Label>Mot de passe</Form.Label>
                        <Form.Control required={false} type="password" placeholder="Password" value={password} onChange={(e) => {setPassword(e.target.value)}} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="editIsAdmin">
                        <Form.Check type="checkbox" label="Est administateur" checked={isAdmin} onChange={(e) => {setIsAdmin(!isAdmin)}}/>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Modifier
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    )
}
export default EditUserManagerModal;