import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector  } from 'react-redux'

import axios from 'axios';


const UpdateStatusLabelComponent = (props) => {
    var running = false;

    useEffect(() => {
        axios.get('data/update/status')
        .then(response => running = response.data);
    });

    return (running?
        <label style={{ fontFamily: "Bangers" }} className="text-secondary">Lancement des calculs en cours d'éxecution ...</label>:''
    );
}

export default UpdateStatusLabelComponent;