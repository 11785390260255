import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector  } from 'react-redux'

import axios from 'axios';


const LoadStatusLabelComponent = (props) => {
    var running = false;

    useEffect(() => {
        axios.get('data/load/status')
        .then(response => running = response.data);
    });

    return (running?
        <label style={{ fontFamily: "Bangers" }} className="text-secondary">Chargement des données en cours d'éxecution ...</label>:''
    );
}

export default LoadStatusLabelComponent;