import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal, Button, ListGroup, CloseButton, Table } from 'react-bootstrap';
import IconButtonComponent from './Others/IconButtonComponent';
import LastImportLabelComponent from './Others/LastImportLabelComponent';
import LoadStatusLabelComponent from './Others/LoadStatusLabelComponent';
import UpdateStatusLabelComponent from './Others/UpdateStatusLabelComponent';
import TitleComponent from './Others/TitleComponent';
import { faRedo, faSearch, faTrashAlt, faCog, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import ImportDataToDWHComponent from './ImportDataToDWHComponent';
import axios from 'axios';

import toastr from 'toastr';
import 'toastr/build/toastr.css'
import useToken from '../hooks/useToken';

import { useSelector } from 'react-redux'
import { setIsAdmin } from '../redux/actions/commonAction';
import DeleteReplenishmentDWHModal from './Modals/DeleteReplenishmentDWHModal';
import UserManagerModal from './Modals/UserManagerModal';

const HomeComponent = () => {
    useEffect(async () => {
        await axios.post('users/isAdmin')
            .then(d => {
                setIsAdmin(d.data)
            })
    }, [])

    const loadData = async (e) => {
        e.preventDefault();
        await axios.post(`data/load`, 
            {
            })
            .then(() => {
                toastr.success('Chargement des données lancé');
            }).catch(() => {
                toastr.error('Impossible de lancer le chargement des données');
            })
    }

    const [showImportDataToDWHModal, setShowImportDataToDWHModal] = useState(false);
    const [showDeleteReplenishmentDWHModal, setShowDeleteReplenishmentDWHModal] = useState(false);
    const [showUserMangerModal, setShowUserMangerModal] = useState(false);

    const [isAdmin, setIsAdmin] = useState(false);

    const { deleteToken } = useToken();


    return (
        <Container fluid>
            <DeleteReplenishmentDWHModal
                showDeleteReplenishmentDWHModal={showDeleteReplenishmentDWHModal}
                setShowDeleteReplenishmentDWHModal={setShowDeleteReplenishmentDWHModal}
            />
            <UserManagerModal
                showUserMangerModal={showUserMangerModal}
                setShowUserMangerModal={setShowUserMangerModal}
            />

            <Row className="mt-5 mb-5">
                <Col><TitleComponent text="Aide à la décision en réapprovisionnement" color="secondary" /></Col>
            </Row>
            <Row className="mt-3">
                <Col><LastImportLabelComponent /></Col>
                <Col><LoadStatusLabelComponent /></Col>
                <Col><UpdateStatusLabelComponent /></Col>
            </Row>
            <Row className="mt-3">
                <Col xs={4}><IconButtonComponent text="Recharger les données maintenant" icon={faRedo} onClick={(e) => loadData(e)} /></Col>
                <Col xs={4}><IconButtonComponent text="Analyser les besoins" icon={faSearch} to="analyse" /></Col>
                <Col xs={4}><IconButtonComponent text="Réinitialiser les quantités validées" onClick={() => setShowDeleteReplenishmentDWHModal(true)} icon={faTrashAlt} /></Col>
            </Row>
            <Row className="mt-8">
                <Col xs={4}><IconButtonComponent text="Paramètres de l'application" icon={faCog} to="settings" /></Col>
                {isAdmin ? <Col xs={4}><IconButtonComponent text="Gestion des utilisateurs" icon={faUser} onClick={() => setShowUserMangerModal(true)} /></Col> : <></>}

                <Col xs={4}><IconButtonComponent text="Déconnexion" icon={faSignOutAlt} onClick={() => deleteToken()} /></Col>
            </Row>
            <ImportDataToDWHComponent show={showImportDataToDWHModal} handler={() => setShowImportDataToDWHModal(false)} />
        </Container>
    );
}

export default HomeComponent;