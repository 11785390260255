import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import TitleComponent from './Others/TitleComponent';
import axios from 'axios';

import toastr from 'toastr';
import 'toastr/build/toastr.css'

import { useHistory } from "react-router-dom";

export const BASE_API_URL = process.env.REACT_APP_API_URL;

const SettingsComponent = () => {

    const [isActive, setIsActive] = useState(false);

    const [settingsData, setSettingsData] = useState({});
    const [numberOfMonthsOfSafetyStock, setNumberOfMonthsOfSafetyStock] = useState(0);
    const [maxMultipleOrderFromCosmos, setMaxMultipleOrderFromCosmos] = useState(0);

    useEffect(() => {
        const fetchProcesses = async () => {
            await axios.get(`replenishmentSettings`)
                        .then((d) => {
                            setSettingsData(d.data);
                            setIsActive(true)
                        })
                        .catch((err) => {
                            console.log(err)
                        });

        };

        fetchProcesses();
    }, [])

    useEffect(() => {
        if(settingsData !== undefined)
        {
            setNumberOfMonthsOfSafetyStock(settingsData.numberOfMonthsOfSafetyStock);
            setMaxMultipleOrderFromCosmos(settingsData.maxMultipleOrderFromCosmos);
        }
    }, [settingsData])

    const handleChange = (e) => {
        e.preventDefault();

        var value = e.target.value;
        var updated = null;
        if(e.target.name === "maxMultipleOrderFromCosmos")
        {
            updated = { ...settingsData,  maxMultipleOrderFromCosmos: parseInt(value) }
        }
        else
        {
            updated = { ...settingsData,  numberOfMonthsOfSafetyStock : value }

        }
        setSettingsData(updated);
    }

    const updateValue = () => {
        axios.post(`replenishmentSettings`, 
        {
           settingsData
        })
        .catch((err) => {
            console.log(err)
        });
    }
    
    const updateProgress = () => {
        axios.post(`data/update`, 
        {})
        .catch((err) => {
            console.log(err)
        });
    }
    const history = useHistory();

    const handleSubmit = () => {
        
        updateValue();
        updateProgress();
        toastr.options.timeOut = 3000;
        toastr.success('Les paramètres ont bien été enregistrés');
        history.push('/')
    }

    return (
        <Container >
            <Row className="mt-5 mb-5">
                <Col><TitleComponent text="Nombre de mois de stock de sécurité" color="pvgreen" /></Col>
            </Row>
            <Row className="mt-5">
                <Form>
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                        <Col sm={7}>
                            <TitleComponent text="Nombre de mois de stock de sécurité :" color="pvgreen" size="1.7em" />
                        </Col>
                        <Col sm={1}>
                            <Form.Control type="number" min="0.1" step="0.1" presicion={1} name="numberOfMonthsOfSafetyStock" value={numberOfMonthsOfSafetyStock} onChange={(e) => {handleChange(e)}} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
                        <Col sm={7}>
                            <TitleComponent text="Multiple Max Commande du Cosmos :" color="pvgreen" size="1.7em" />
                        </Col>
                        <Col sm={1}>
                            <Form.Control type="number" name="maxMultipleOrderFromCosmos" value={maxMultipleOrderFromCosmos} onChange={(e) => {handleChange(e)}}/>
                        </Col>
                    </Form.Group>
                </Form>
            </Row>
            <Row className="mt-5">
                <Col sm={{ offset: 4, span:2}}>
                    <Link to="/">
                        <Button
                            variant="default"
                            className="bg-pvgreen"
                            size="lg"
                            style={{ width: "335px", height: "75px" }}>
                            Annuler
                        </Button>
                    </Link>
                </Col>
                <Col>
                    <Button
                        disabled={!isActive}
                        variant="default"
                        className="bg-pvgreen"
                        size="lg"
                        style={{ width: "335px", height: "75px" }}
                        onClick={() => { handleSubmit()}}
                    >
                        Sauvegarder et relancer les calculs
                    </Button>
                </Col>

            </Row>
        </Container>
    );
}

export default SettingsComponent;