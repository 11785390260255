import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { faHome, faPaperPlane, faFileExcel } from '@fortawesome/free-solid-svg-icons'

import DeliverySimulationTableComponent from './analyse/DeliverySimulationTableComponent';
import TableComponent from './analyse/TableComponent';

import IconButtonComponent from './Others/IconButtonComponent';
import LastImportLabelComponent from './Others/LastImportLabelComponent';
import TableFiltersComponent from './analyse/TableFiltersComponent';

import { useDispatch, useSelector  } from 'react-redux'
import axios from 'axios';

import toastr from 'toastr';
import 'toastr/build/toastr.css'
import { setIsLoading } from '../redux/actions/commonAction';

import { saveAs } from 'file-saver'
import useToken from '../hooks/useToken';

const BASE_API_URL = process.env.REACT_APP_API_URL;

const AnalyseComponent = () => {
    const { token } = useToken();
    
    const fetchOptions = {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
        }
    }

    const dispatch = useDispatch();

    const filters = useSelector((state) => {
        return state.filters;
    });

    const validate = async (e) => {
        var target = e.currentTarget;

        target.disabled = true;

        await axios.post(`validateReplenishments`)
                    .then(async function(){
                        toastr.options.timeOut = 3000;
                        toastr.info('Les quantitées ont bien été validées');
                        dispatch(setIsLoading(true));
                        toastr.options.timeOut = 0;
                        toastr.warning('Export en cours ...');

                        const current = new Date();
                        fetch(`${BASE_API_URL}/exportCF`, fetchOptions)
                        .then(response => response.blob())
                        .then(blob =>{
                            toastr.clear();
                            toastr.options.timeOut = 3000;
                            saveAs(blob, `Export_CF_${current.getDate()}${current.getMonth()+1}${current.getFullYear()}.xlsx`)
                            target.disabled = false;
                        });
                    });
    }

    const exportExcel = (e) => {
        var target = e.currentTarget;

        target.disabled = true;

        const current = new Date();

        toastr.options.timeOut = 0;
        toastr.warning('Récupération des données (1/2)', 'Export en cours ...');

        fetch(`${BASE_API_URL}/export_part?part=1`, fetchOptions)
        .then(() => {
            toastr.clear();
            toastr.info('Récupération des données (2/2)', 'Export en cours ...');

            fetch(`${BASE_API_URL}/export_part?part=2`, fetchOptions)
            .then(() => {
                toastr.clear();
                toastr.success('Téléchargement', 'Export en cours ...');

                fetch(`${BASE_API_URL}/export`, fetchOptions)
                    .then(response => response.blob())
                    .then(blob => {
                        toastr.clear();
                        toastr.options.timeOut = 3000;
                        saveAs(blob, `${current.getDate()}${current.getMonth()+1}${current.getFullYear()}_Export.xlsx`)
                        target.disabled = false;

                    })
            })
        })
    }

    return (
        <Container fluid className="mt-3">
            <Row>
                <Col sm="2">
                    <DeliverySimulationTableComponent filters={filters} />
                </Col>
                <Col className="text-start mt-5">
                    <LastImportLabelComponent />
                </Col>
                <Col sm={{ offset: 0, span: 6 }}>
                    <IconButtonComponent text="Revenir à l'accueil" icon={faHome} to="/" />
                </Col>
            </Row>
            <Row className="mt-3">
                <TableFiltersComponent />
                <Col sm>
                    <IconButtonComponent text="Exporter sur XLS" onClick={(e) => exportExcel(e)} icon={faFileExcel} />
                </Col>
                <Col sm>
                    <IconButtonComponent text="Valider suggestions et exporter les CF" icon={faPaperPlane} onClick={(e) => validate(e)} />
                </Col>
            </Row>
            <Row className="mt-5">
                <TableComponent filters={filters} />
            </Row>
        </Container>
    );
}

export default AnalyseComponent;