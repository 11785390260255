import { ActionTypes } from "../constants/commonActionTypes";

const initialState = {
    isLoading : true,
    isLoadingEqs40 : true,
    date : '',
    token : '',
    isAdmin : false
};

export const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_TOKEN: 
        {
            return { ...state, token: action.payload }
        }
        case ActionTypes.SET_IS_ADMIN: 
        {
            return { ...state, isAdmin: action.payload }
        }
        case ActionTypes.SET_IS_LOADING: 
        {
            return { ...state, isLoading: action.payload }
        }
        case ActionTypes.SET_IS_LOADING_EQS40: 
        {
            return { ...state, isLoadingEqs40: action.payload }
        }
        case ActionTypes.SET_LAST_TRT_DATE: 
        {
            return { ...state, date: action.payload }
        }
        default:
            return state;
    }
}